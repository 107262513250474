import cn from "clsx";

import "./statistics-group.scss";
import "./statistics-group-vertical.scss";

import { Alignment } from "design-system/types/types";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import {
  Statistic,
  StatisticProps,
} from "design-system/components/primitives/statistic/statistic";

export type StatisticsGroupLayout = "horizontal" | "vertical";

export interface StatisticsGroupProps {
  headerContent?: ComponentHeaderProps;
  align?: Alignment;
  layout?: StatisticsGroupLayout;
  media?: MediaAssetProps;
  statistics: Array<StatisticProps>;
  HeadingLevel?: "h2" | "h3" | "h4";
}

/**
 * ## See it in use on...
 * - A [landing page](/story/example-pages-landing-pages-card-topper--story-alt)
 *
 * - **`id: CB-015-000-00`**
 * - **`data-region: cb__statistics`**
 */
export function StatisticsGroup({
  headerContent,
  align = "full",
  layout = "horizontal",
  media,
  statistics,
  HeadingLevel = "h2",
}: StatisticsGroupProps) {
  const count = statistics.length;
  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--statistics-group",
  );
  const statisticsClass = cn(
    `hbs-statistics-group hbs-statistics-group--${count}`,
    media && "hbs-statistics-group--has-media",
    layout && `hbs-statistics-group--${layout}`,
  );

  const aspectRatio = layout === "vertical" ? undefined : "3/2";

  return (
    <div className={wrapperClass} data-region="cb__statistics">
      <ComponentHeader {...headerContent} HeadingLevel={HeadingLevel} />
      <section className={statisticsClass}>
        {/* Media */}
        {media && (
          <div className="hbs-statistics-group__media">
            <MediaAsset {...media} aspectRatio={aspectRatio} />
          </div>
        )}

        {/* Stats */}
        <div className="hbs-statistics-group__list">
          {statistics.map((statistic, i) => (
            <Statistic {...statistic} key={i} />
          ))}
        </div>
      </section>
    </div>
  );
}
