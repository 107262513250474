import { TeaseRowArticlesEntry } from "frontend/contentful/schema/blocks";
import { TeaseRow } from "design-system/components/blocks/tease-row/tease-row";
import { TeaseStyle } from "design-system/types/types";
import { createComponentHeaderProps } from "frontend/contentful/components/primitives/component-header.props";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { createUseSearchProps } from "frontend/contentful/components/utils/use-search.props";
import { useSearch } from "frontend/hooks/use-search";
import {
  createModernSearchArticleTease,
  createSearchArticleTease,
} from "frontend/contentful/search/search-article-tease";
import { LegacySearchHit, ModernSearchHit } from "frontend/types";
import { LazyTeaseRowTease } from "design-system/components/blocks/tease-row/tease-row-tease";
import { CreateEditAttributesFn } from "frontend/contentful/lib/edit-attributes-factory";
import { getSiteId } from "frontend/lib/get-site-id";
import { usePageProps } from "frontend/hooks/use-page-props";
import { useContentful } from "frontend/hooks/use-contentful";

const CuratedContentfulTeaseRowArticles = ({
  entry,
  createEditAttributes,
}: {
  entry: TeaseRowArticlesEntry;
  createEditAttributes: CreateEditAttributesFn;
}) => {
  const ids = entry.fields.teasesCurated || [];
  const teases: Array<LazyTeaseRowTease> = [];

  const { data } = useSearch<ModernSearchHit>({
    index: "modern",
    query: ids.map((id) => `id:${id}`).join(" OR "),
    size: entry.fields.teasesCurated?.length || 25,
  });

  /**
   * Sorting hits based on the order of IDs.
   *
   * TODO: Consider updating /api/search/query to accept
   * custom sorting algorithm
   */
  const sortedHits = data?.hits.toSorted((a, b) => {
    return ids.indexOf(a.id) - ids.indexOf(b.id);
  });
  const page = usePageProps();
  const { getEntry } = useContentful();
  const site = getEntry(page.site);
  const siteId = getSiteId(site);

  const headerContent = createComponentHeaderProps(entry.fields.header);

  if (!sortedHits || sortedHits.length === 0) return null;

  sortedHits.forEach((item) => {
    const props = createModernSearchArticleTease(item, siteId);
    if (props) teases.push({ name: "ArticleTease", props });
  });

  return (
    <TeaseRow
      teases={teases}
      wrap={entry.fields.wrap ? "wrap" : "no-wrap"}
      align={entry.fields.alignment}
      teaseStyle={entry.fields.teaseStyle as TeaseStyle}
      headerContent={headerContent}
      editAttributes={{
        teases: createEditAttributes({
          entry: entry.fields.articleTeasesQuery,
        }),
      }}
      imagesInset={entry.fields.imagesInset || false}
    />
  );
};

const QueryContentfulTeaseRowArticles = ({
  entry,
  createEditAttributes,
}: {
  entry: TeaseRowArticlesEntry;
  createEditAttributes: CreateEditAttributesFn;
}) => {
  const teases: Array<LazyTeaseRowTease> = [];

  const page = usePageProps();
  const { getEntry } = useContentful();
  const site = getEntry(page.site);
  const siteId = getSiteId(site);

  const searchProps = createUseSearchProps(entry.fields.articleTeasesQuery);
  const { data } = useSearch<LegacySearchHit>(searchProps, {
    dedupe: true,
    defaultSize: 3,
  });

  data?.hits.forEach((item) => {
    const props = createSearchArticleTease(item, siteId, {
      width: "486px",
      height: "308px",
      aspectRatio: "3/2",
    });
    if (props) teases.push({ name: "ArticleTease", props });
  });

  return (
    <TeaseRow
      teases={teases}
      wrap={entry.fields.wrap ? "wrap" : "no-wrap"}
      align={entry.fields.alignment}
      teaseStyle={entry.fields.teaseStyle as TeaseStyle}
      headerContent={createComponentHeaderProps(entry.fields.header)}
      editAttributes={{
        teases: createEditAttributes({
          entry: entry.fields.articleTeasesQuery,
        }),
      }}
      imagesInset={entry.fields.imagesInset || false}
    />
  );
};

export const ContentfulTeaseRowArticles =
  createContentfulComponent<TeaseRowArticlesEntry>(
    ({ entry, createEditAttributes }) => {
      if (entry.fields.teasesCurated && entry.fields.teasesCurated.length > 0) {
        return (
          <CuratedContentfulTeaseRowArticles
            entry={entry}
            createEditAttributes={createEditAttributes}
          />
        );
      }

      if (entry.fields.articleTeasesQuery) {
        return (
          <QueryContentfulTeaseRowArticles
            entry={entry}
            createEditAttributes={createEditAttributes}
          />
        );
      }

      return null;
    },
  );
