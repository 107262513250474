import { TeaseRowProgramCardsEntry } from "frontend/contentful/schema/blocks";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { createComponentHeaderProps } from "frontend/contentful/components/primitives/component-header.props";
import { createUseSearchProps } from "../../utils/use-search.props";
import { useSearch } from "frontend/hooks/use-search";
import { createSearchProgramTease } from "frontend/contentful/search/search-program-tease";
import { ModernSearchHit } from "frontend/types";
import { createProgramTeaseProps } from "./program-tease.props";
import {
  CourseCardTeaseRow,
  CourseCardTeaseRowProps,
} from "design-system/sites/online/components/course-card-tease-row/course-card-tease-row";
import { CourseCardsProps } from "design-system/sites/online/components/course-card/course-card";
import { usePageProps } from "frontend/hooks/use-page-props";

interface TeaseRowProgramCardsOptions {
  headerAbove?: boolean;
}

export const ContentfulTeaseRowProgramCards = createContentfulComponent<
  TeaseRowProgramCardsEntry,
  TeaseRowProgramCardsOptions
>(({ entry, options }) => {
  const { request } = usePageProps();
  const isExEd = request.domain === "exed" ? true : false;
  const headerAbove = options?.headerAbove || false;
  const courseHeaderAbove = entry.fields.header ? headerAbove : false;
  const multiCourseHeaderAbove = courseHeaderAbove || false;

  const props: CourseCardTeaseRowProps = {
    wrap: entry.fields.wrap ? "wrap" : "no-wrap",
    align: entry.fields.alignment!,
    headerContent: createComponentHeaderProps(entry.fields.header),
    teases: [],
    teasesPerRow: entry.fields.teasesPerRow,
    isMultiCourse: false,
    isExEd: isExEd,
    HeadingLevel: {
      titleHeader: headerAbove ? "h3" : "h2",
      courseHeader: courseHeaderAbove ? "h4" : "h3",
      multiCourseHeader: multiCourseHeaderAbove ? "h5" : "h4",
    },
  };

  entry.fields.teasesManual?.forEach((program) => {
    const programCardTeaseProps = createProgramTeaseProps(program);
    if (programCardTeaseProps) {
      props.teases?.push(programCardTeaseProps);
    }
  });

  if (entry.fields.teasesQuery) {
    const searchProps = createUseSearchProps(entry.fields.teasesQuery);
    const { data } = useSearch<ModernSearchHit>(searchProps);

    data?.hits.forEach((item) => {
      const programCardTeaseProps = createSearchProgramTease(
        item,
        "card",
      ) as CourseCardsProps;
      if (programCardTeaseProps) props.teases?.push(programCardTeaseProps);
    });
  }

  return <CourseCardTeaseRow {...props} />;
});
