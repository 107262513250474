import { ExpandableProgramTeaseProps } from "design-system/components/primitives/program-tease/expandable-program-tease";
import { ProgramTeaseProps } from "design-system/components/primitives/program-tease/program-tease";
import {
  ExEdProgramSearchHit,
  ModernSearchHit,
  SearchHit,
  SearchDisplay,
} from "frontend/types";
import { stripHtml } from "../components/utils/strip-html";
import { isSearchHitType } from "./is-search-hit-type";
import { CourseCardsProps } from "design-system/sites/online/components/course-card/course-card";

export function createSearchProgramTease(
  data?: SearchHit,
  teaseStyle?: ProgramTeaseProps["style"] | "card",
) {
  if (isSearchHitType<ExEdProgramSearchHit>("modernProgram", data)) {
    if (teaseStyle === "expandable") {
      return createModernSearchExpandableProgramTease(data);
    } else if (teaseStyle === "card") {
      return createModernSearchProgramTeaseCard(data);
    } else {
      return createModernSearchProgramTease(data);
    }
  }
}

export function createModernSearchProgramTease(
  data: ModernSearchHit,
): ProgramTeaseProps {
  const props: ProgramTeaseProps = {
    type: "Program",
    title: data.title,
    link: data.url,
    tease: stripHtml(data.description || ""),
  };

  // TODO: Add category url support to modern search schema
  props.category = { label: "Program" };

  if (isSearchHitType<SearchDisplay>("modern", data)) {
    if (data.display?.thumbnail?.src) {
      props.image = data.display.thumbnail;
    }
  }

  return props;
}

export function createModernSearchProgramTeaseCard(
  data: ExEdProgramSearchHit,
): CourseCardsProps {
  const props: CourseCardsProps = {
    type: "Course",

    id: data.display?.abbreviation || data.id,
    title: data.title,
    description: data.description && stripHtml(data.description),
    link: data.url,

    sessions: data.display?.sessions?.map((session) => ({
      startDate: session.startDate,
      endDate: session.endDate,
      format: session.format,
      fee: session.fee,
    })),

    // TODO: Update to support HBS Online courses
    isExEd: true,
  };

  return props;
}

export function createModernSearchExpandableProgramTease(
  data: ExEdProgramSearchHit,
): ExpandableProgramTeaseProps {
  const tbcStatus = "To Be Confirmed";
  const props: ExpandableProgramTeaseProps = {
    type: "Program",
    style: "expandable",

    id: data.display?.abbreviation || data.id,
    url: data.url,
    title: data.title,
    description: data.description && stripHtml(data.description),
    topics: data.topic?.map((topic) => ({ title: topic })),
    industry: data.industry?.map((industry) => ({ title: industry })),
    format: data.format,

    sessions: data.display?.sessions?.map((session) => ({
      startDate: session.startDate,
      endDate: session.endDate,
      dateLabel:
        session.startDate === undefined || session.endDate === undefined
          ? tbcStatus
          : undefined,
      format: session.format,
      status:
        session.startDate === undefined || session.endDate === undefined
          ? "Check Back to Apply"
          : session.status,
      fee:
        session.startDate === undefined || session.endDate === undefined
          ? tbcStatus
          : session.fee,
      eyebrowStatus:
        session.startDate === undefined || session.endDate === undefined
          ? "to Be Confirmed"
          : undefined,
    })),
  };

  return props;
}
