import "design-system/components/blocks/tease-row/tease-row.scss";
import "design-system/components/blocks/tease-row/tease-row-2-up.scss";
import "design-system/components/blocks/tease-row/tease-row-3-up.scss";
import "./course-card-tease-row.scss";

import cn from "clsx";
import { Alignment } from "design-system/types/types";
import {
  CourseCardsProps,
  CourseCard,
} from "design-system/sites/online/components/course-card/course-card";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import { TeaseRowWrap } from "design-system/components/blocks/tease-row/tease-row";

export interface CourseCardTeaseRowProps {
  align?: Alignment;
  className?: string;
  headerContent?: ComponentHeaderProps;
  teases?: Array<CourseCardsProps>;
  teasesPerRow?: string;
  wrap?: TeaseRowWrap;
  isMultiCourse?: boolean;
  isExEd?: boolean;
  HeadingLevel?: {
    titleHeader: "h2" | "h3";
    courseHeader: "h3" | "h4";
    multiCourseHeader: "h4" | "h5";
  };
}

/**
 * ## Tips
 *
 * - Don't mix Multi-course teases with normal course teases
 * - Multi-course tease rows teasesPerRow should only be 1 or 2
 * - Normal course tease rows teasesPerRow can be 2, 3 or 4-up
 */
export function CourseCardTeaseRow({
  align = "wide",
  className,
  headerContent,
  teases,
  teasesPerRow,
  wrap = "wrap",
  isMultiCourse,
  HeadingLevel = {
    titleHeader: "h2",
    courseHeader: "h3",
    multiCourseHeader: "h4",
  },
}: CourseCardTeaseRowProps) {
  if (!Array.isArray(teases)) teases = [];
  const rowUp = teasesPerRow || teases.length;
  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--tease-row",
  );

  const teaseRowClass = cn(
    "hbs-tease-row",
    "hbs-tease-row--courses",
    `hbs-tease-row--${rowUp}-up`,
    `hbs-tease-row--${wrap}`,
    isMultiCourse && "hbs-tease-row--multicourse",
    className,
  );

  return (
    <div className={wrapperClass}>
      <section className="hbs-tease-row-container">
        {headerContent && (
          <ComponentHeader
            {...headerContent}
            HeadingLevel={HeadingLevel?.titleHeader}
          />
        )}

        <ul className={teaseRowClass}>
          {teases.map((tease, i) => (
            <li className="hbs-tease-row__tease-mod" key={i}>
              <CourseCard {...tease} HeadingLevel={HeadingLevel} />
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
