import "./course-card.scss";

import cn from "clsx";

import { useId } from "react";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { ProgramTeaseCategoryProps } from "design-system/components/primitives/program-tease/program-tease";
import { Link } from "design-system/components/primitives/link/link";
import {
  useProgramComparison,
  ProgramToCompare,
} from "design-system/hooks/use-program-comparison";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { Icon } from "design-system/components/icons/icon";
import { IconArrow } from "design-system/components/icons/icon-arrow";
import { stringDateRange } from "design-system/utils/date-format";
import { DataLayerEvent, useDataLayer } from "frontend/hooks/use-data-layer";
import { useTranslations } from "design-system/hooks/use-translations";

export interface CourseCardsProps extends ProgramToCompare {
  type: "Course";
  id: string;
  title: string;
  description?: string;
  link?: string;
  length?: string;
  hours?: string;
  category?: ProgramTeaseCategoryProps;
  applyDate?: string;
  price?: string;
  isMultiCourse?: boolean;
  isExEd?: boolean;
  multiCourseLength?: number;
  courseList?: Array<string>;
  media?: MediaAssetProps;
  HeadingLevel?: {
    courseHeader: "h3" | "h4";
    multiCourseHeader: "h4" | "h5";
  };
}

export function CourseCard(props: CourseCardsProps) {
  const { programsToCompare, addProgramToCompare, removeProgramToCompare } =
    useProgramComparison();
  const COMPARISON_LIMIT = 8;
  const checkboxId = useId();
  const { pushDataLayer } = useDataLayer();
  const { t } = useTranslations();

  const {
    id,
    category,
    media,
    link,
    description,
    title,
    applyDate,
    price,
    length,
    hours,
    isMultiCourse = false,
    multiCourseLength,
    courseList,
    isExEd = false,
    sessions,
    HeadingLevel = {
      multiCourseHeader: "h4",
      courseHeader: "h3",
    },
  } = props;

  const wrapperClass = cn(
    "hbs-course-card",
    isMultiCourse && "hbs-course-card--multicourse",
    !media && "hbs-course-card--no-image",
  );
  let categoryLabel = category?.label || "";
  const dataLayer: DataLayerEvent = {
    /* eslint-disable camelcase */
    event: "program_finder_result_click",
    event_data: {},
    /* eslint-enable camelcase */
  };

  const OnlineCourseDetails = () => {
    return (
      <div className="hbs-course-card__details">
        <ul>
          {price && (
            <li className="hbs-course-card__detail">
              <p>
                {price && price}
                {applyDate && `, ${applyDate}`}
              </p>
            </li>
          )}
          {length && (
            <li className="hbs-course-card__detail">
              <p>
                {length}
                {hours && `, ${hours}`}
              </p>
            </li>
          )}
        </ul>
      </div>
    );
  };

  let fee: string | undefined;
  let format: string | undefined;
  let sessionDateRange: string | undefined;

  if (isExEd) {
    if (!sessions?.length) return null;

    sessions.forEach((firstSession) => {
      if (!sessionDateRange || sessionDateRange.indexOf("Last Ran:") > -1) {
        sessionDateRange =
          stringDateRange(firstSession.startDate, firstSession.endDate) ||
          t("toBeConfirmed");
        if (
          firstSession.startDate &&
          firstSession.startDate.indexOf("Last Ran:") > -1
        ) {
          sessionDateRange = firstSession.startDate;
        }
        format = firstSession.format;
        fee = firstSession.fee;
        if (firstSession.status) categoryLabel = firstSession.status;
      }
    });
  }

  const ExEdCourseDetails = () => {
    return (
      <div className="hbs-course-card__details">
        <ul>
          <li className="hbs-course-card__detail">
            <p>{sessionDateRange}</p>
          </li>
          {format && (
            <li className="hbs-course-card__detail">
              <p>{format}</p>
            </li>
          )}
          {fee && (
            <li className="hbs-course-card__detail">
              <p>
                <strong>{fee}</strong>
              </p>
            </li>
          )}
        </ul>
      </div>
    );
  };

  const CourseDetails = isExEd ? ExEdCourseDetails : OnlineCourseDetails;

  return (
    <div className={wrapperClass}>
      <div
        className="hbs-course-card__header"
        style={
          category?.label && category.gradientImage?.image
            ? { backgroundImage: `url(${category.gradientImage?.image.src})` }
            : undefined
        }
      ></div>
      <div className="hbs-course-card__wrapper">
        <div className="hbs-course-card__inner">
          <div className="hbs-course-card__body">
            {isMultiCourse && media && (
              <MediaAsset
                className="hbs-course-card__media"
                {...media}
                aspectRatio="2/3"
              />
            )}
            <div className="hbs-course-card__content">
              <div className="hbs-course-card__content-inner">
                {isMultiCourse && media && (
                  <MediaAsset
                    className="hbs-course-card__media hbs-course-card__media-float"
                    {...media}
                    aspectRatio="2/3"
                  />
                )}

                {category?.label && (
                  <p className="hbs-course-card__category">
                    {category.link ? (
                      <Link href={category.link} className="category-link">
                        {categoryLabel}
                      </Link>
                    ) : (
                      categoryLabel
                    )}
                  </p>
                )}

                <HeadingLevel.courseHeader className="hbs-course-card__title">
                  <Link
                    href={link}
                    className="hbs-course-card__link"
                    onClick={() => {
                      pushDataLayer({
                        event: "program_finder_result_click",
                        /* eslint-disable camelcase */
                        event_data: {
                          program_action: "program cta click",
                          program_name: title,
                        },
                        /* eslint-enable camelcase */
                      });
                    }}
                  >
                    {title}
                  </Link>
                </HeadingLevel.courseHeader>

                {description && (
                  <p className="hbs-course-card__description">{description}</p>
                )}
              </div>
              <CourseDetails />
            </div>
          </div>
          {isMultiCourse && (
            <>
              <div className="hbs-course-card__courses">
                <div className="hbs-course-card__courses-header">
                  <div className="hbs-course-card__courses-length">
                    {multiCourseLength && multiCourseLength}
                  </div>
                  <HeadingLevel.multiCourseHeader className="hbs-course-card__courses-label">
                    Course Program
                  </HeadingLevel.multiCourseHeader>
                </div>

                {courseList && courseList.length > 0 && (
                  <ul className="hbs-course-card__courses-list">
                    {courseList.map((course, i) => (
                      <li
                        className="hbs-course-card__courses-list-item"
                        key={i}
                      >
                        {course}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="hbs-course-card__multicourse-mobile">
                <CourseDetails />
              </div>
            </>
          )}
        </div>

        <div className="hbs-course-card__footer">
          <div className="hbs-course-card__compare">
            <RadixCheckbox.Root
              id={checkboxId}
              className="hbs-course-card__checkbox"
              checked={!!programsToCompare.find((p) => p.id === id)}
              disabled={
                !programsToCompare.find((p) => p.id === id) &&
                programsToCompare.length >= COMPARISON_LIMIT
              }
              onClick={(e) => e.stopPropagation()}
              onCheckedChange={(checked) => {
                if (checked) {
                  addProgramToCompare(props);
                  /* eslint-disable camelcase */
                  dataLayer.event_data = {
                    program_action: "compare add click",
                    program_name: title,
                  };
                  pushDataLayer(dataLayer);
                  /* eslint-enable camelcase */
                } else {
                  removeProgramToCompare(props);
                  /* eslint-disable camelcase */
                  dataLayer.event_data = {
                    program_action: "compare remove click",
                    program_name: title,
                  };
                  pushDataLayer(dataLayer);
                  /* eslint-enable camelcase */
                }
              }}
            >
              <RadixCheckbox.Indicator>
                <Icon name="check" className="hbs-course-card__checkbox-icon" />
              </RadixCheckbox.Indicator>
              <span className="hbs-global-visually-hidden">{`Compare ${title}`}</span>
            </RadixCheckbox.Root>
            <label
              htmlFor={checkboxId}
              className="hbs-course-card__checkbox-label"
              aria-hidden="true"
              onClick={(e) => e.stopPropagation()}
            >
              Compare
            </label>
          </div>
          <Link
            href={link}
            className="hbs-course-card__footer-link"
            aria-label={`Learn more about ${title}`}
            onClick={() => {
              pushDataLayer({
                event: "program_finder_result_click",
                /* eslint-disable camelcase */
                event_data: {
                  program_action: "program cta click",
                  program_name: title,
                },
                /* eslint-enable camelcase */
              });
            }}
          >
            Learn more
            <IconArrow className="hbs-course-card__footer-link-icon" />
          </Link>
        </div>
      </div>
    </div>
  );
}
