import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { StatisticsGroupEntry } from "frontend/contentful/schema/blocks";
import {
  StatisticsGroup,
  StatisticsGroupProps,
} from "design-system/components/blocks/statistics-group/statistics-group";
import { createComponentHeaderProps } from "../primitives/component-header.props";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createStatisticProps } from "../semantics/statistic.props";

interface StatisticsGroupOptions {
  headerAbove?: boolean;
}

export const ContentfulStatisticsGroup = createContentfulComponent<
  StatisticsGroupEntry,
  StatisticsGroupOptions
>(({ entry, options }) => {
  const props: StatisticsGroupProps = {
    headerContent: createComponentHeaderProps(entry.fields.header),
    statistics: [],
    media: createMediaAssetProps(entry.fields.media),
    layout: entry.fields.layout === "side-by-side" ? "vertical" : "horizontal",
    align: entry.fields.alignment,
  };

  entry.fields.statistics?.forEach((entry) => {
    const statisticProps = createStatisticProps(entry);
    if (statisticProps) props.statistics?.push(statisticProps);
  });

  props.HeadingLevel = options?.headerAbove ? "h3" : "h2";

  return <StatisticsGroup {...props} />;
});
