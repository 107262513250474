import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { useContentful } from "frontend/hooks/use-contentful";
import { CourseCardsProps } from "design-system/sites/online/components/course-card/course-card";
import { ProgramPageEntry } from "frontend/contentful/schema/pages";
import { documentToString } from "frontend/contentful/lib/document-to-string";
import { Session } from "design-system/types/exed-schema";
import { usePageProps } from "frontend/hooks/use-page-props";
import { formatDate } from "design-system/utils/date-format";
import { useTranslations } from "design-system/hooks/use-translations";

export const createProgramTeaseProps = createContentfulProps<
  ProgramPageEntry,
  CourseCardsProps
>(({ entry, createEditAttributes }) => {
  const { getEntry, getEntryUrl, getEntries } = useContentful();
  const { request } = usePageProps();
  const { t } = useTranslations();
  const isExEd = request.domain === "exed" ? true : false;
  const programEntry = getEntry(entry);
  if (!programEntry) return;

  const upcomingSessions: Array<Session> = [];
  const sortedProgramSessions = getEntries(programEntry.fields.sessions).sort(
    (a, b) => {
      if (!a.fields.startDate || !b.fields.startDate) return 0;
      return (
        new Date(a.fields.startDate).getTime() -
        new Date(b.fields.startDate).getTime()
      );
    },
  );

  sortedProgramSessions.forEach((sessionRef) => {
    const sessionEntry = getEntry(sessionRef);

    if (sessionEntry) {
      let start = sessionEntry.fields.startDate;
      let status = getEntry(sessionEntry.fields.status)?.fields.title;
      if (
        (sessionRef.fields.startDate &&
          new Date(sessionRef.fields.startDate).getTime() < Date.now() &&
          sessionRef.fields.overrideSessionDisplay !== "yes") ||
        (sessionRef.fields.endDate &&
          new Date(sessionRef.fields.endDate).getTime() < Date.now())
      ) {
        const date = formatDate(sessionEntry.fields.startDate);
        start = `Last Ran: ${date}`;
        status = `Last Ran: ${date}`;
      } else if (!sessionRef.fields.startDate) {
        status = t("startDateToBeConfirmed");
      }

      const sessionProps: Session = {
        startDate: start,
        overrideSessionDisplay: sessionEntry.fields.overrideSessionDisplay,
        endDate: sessionEntry.fields.endDate,
        format: getEntry(sessionEntry.fields.format)?.fields.title || "",
        fee: sessionEntry.fields.fee,
        status: status,
      };
      upcomingSessions.push(sessionProps);
    }
  });

  const eyebrow = upcomingSessions[0]?.status || "";

  const props: CourseCardsProps = {
    type: "Course",
    id: programEntry.fields.abbreviation || "",
    title: programEntry.fields.title || "",
    description: documentToString(programEntry.fields.description),
    link: getEntryUrl(programEntry),
    sessions: upcomingSessions,
    category: { label: eyebrow, link: "" },

    // TODO: Update to support HBS Online courses
    isExEd: isExEd,

    editAttributes: {
      id: createEditAttributes({
        entry: programEntry,
        fieldId: "abbreviation",
      }),
      title: createEditAttributes({ entry: programEntry, fieldId: "title" }),
      description: createEditAttributes({
        entry: programEntry,
        fieldId: "description",
      }),
      link: createEditAttributes({ entry: programEntry, fieldId: "url" }),
    },
  };

  return props;
});
